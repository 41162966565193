<template>
  <div class="fill-parent">
    <div class="header">
      <h2><img :src="require('@/assets/recycle/images/logo-h.png')" style="height: 2rem;" alt="長腳衣櫥"/></h2>
      <a href="#" @click.prevent="showWcLinkInfo"><img :src="require('@/assets/recycle/images/user-icon.png')" /></a>
    </div>
    <div class="map">
      <RealTimeMap></RealTimeMap>
    </div>
    <div class="footer">
      <p>本服務由<a href="https://walkingcloset.co" target="_blank">長腳衣櫥</a>提供</p>
    </div>
    <WcAlertModal v-if="showWCClickInfo" @ok="showWCClickInfo = false">
      <div>
        <div class="text-center d-lg-none py-4">
          <p class="m-0">前往<a href="https://walkingcloset.network/closet">長腳衣櫥</a></p>
        </div>

        <div class="d-none d-lg-block">
          <p class="text-center m-0">掃描QR Code前往長腳衣櫥</p>
          <img :src="require('@/assets/recycle/images/qrcode.png')" style="width: 400px;" />
        </div>
      </div>
    </WcAlertModal>
  </div>
</template>

<script>
// @ is an alias to /src
import RealTimeMap from '@/components/recycle/RealTimeMap.vue';
import WcAlertModal from '@/components/commons/WcAlertModal.vue';

import { mapActions, mapState } from 'vuex';

// import imglyRemoveBackground from "@imgly/background-removal";

export default {
  name: 'RecycleTest',
  data() {
    return {
      showWCClickInfo: false,
    };
  },
  components: {
    RealTimeMap,
    WcAlertModal,
  },
  computed: {
    ...mapState(['clothCategories']),
    
	},
  watch: {
    
  },
  async mounted() {
	},
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'showMsgModal', 'updateCloth']),
    showWcLinkInfo() {
      this.showWCClickInfo = true;
    }
  }
}
</script>

<style lang="scss" scoped>
  .fill-parent {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    flex: 0 0 auto;
    padding: .2rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0;
      padding-bottom: .3rem;
      >img {
        height: 2rem;
      }
    }
    >a {
      display: block;
      >img {
        height: 2.3rem;
      }
    }
  }

  .map {
    flex: 1 1;
  }

  .footer {
    flex: 0 0 auto;
    text-align: end;
    padding: 1rem;
    font-size: .8rem;
    color: #999;
    p {
      margin: 0;
    }
  }
</style>
